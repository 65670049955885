import { Link } from "gatsby";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
`;

export const NewsDateContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

export const NewsDate = styled.div`
    color: #fff;
    height: 75px;
    width: 75px;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const NewsInfo = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 10px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    font-size: 13px;
    color: #808080;
`;
export const LinkToDRV = styled(Link)`
    margin-left: 5px;
    color: #0e6faa;
`;

export const LinkToNews = styled(Link)`
    margin-left: 5px;
    color: #0e6faa;
`;

export const NewsInfoText = styled.div`
    margin-left: 35px;
`;
export const NewsContent = styled.div`
    color: #808080;
`;
