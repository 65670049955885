import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import NewsDetails from "../components/OurCompany/News/NewsDetails";

import Favicon from "../images/Favicon.png";

const NewsDetailsPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>News - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Stay updated with the latest news in the Last-Mile Delivery industry. Gain insights on first-party delivery and third-party delivery, safety, productivity, and industry innovations. Learn how Drivosity is leading the way in last-mile delivery solutions. Read more today."/>
            </Helmet>
            <Layout component={<NewsDetails/>}/>
        </>
    )
}

export default NewsDetailsPage;
